import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import CenteredCard from "../../layout/CenteredCard";
import { Alert, Typography } from "@mui/material";

export default observer(function ServerError() {
  const { commonStore } = useStore();

  return (
    <CenteredCard>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Server Error
      </Typography>
      <Alert sx={{ my: 3 }} severity="error">
        {commonStore.error?.message}
      </Alert>
      {commonStore.error?.details && (
        <>
          <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
            Stack trace
          </Typography>
          <code>{commonStore.error.details}</code>
        </>
      )}
    </CenteredCard>
  );
});
