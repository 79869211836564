import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { env } from "../../app/utils/env";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { GalleryPhoto } from "../models/galleryPhoto";
import agent from "../api/agent";
import AdminLayout from "../layout/AdminLayout";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from '@mui/icons-material/Info';
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f6f6f6",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function PhotoGallery() {
  const [photos, setPhotos] = useState<GalleryPhoto[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchPhotos();
  }, []);

  useEffect(() => {
    let lightbox: any = new PhotoSwipeLightbox({
      gallery: "#photo-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  const fetchPhotos = async () => {
    const response = await agent.GalleryPhotos.list();
    setPhotos(response);
  };

  const handleDelete = async (id: string) => {
    await agent.GalleryPhotos.delete(id);
    fetchPhotos();
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      await agent.GalleryPhotos.create(formData);
      fetchPhotos();
    } catch (error) {
      console.error("There was an error uploading the file:", error);
    }
  };

  return (
    <AdminLayout title="Görsel Galerisi">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{ mr: 5 }}
            >
              Görsel Yükle
              <VisuallyHiddenInput type="file" onChange={handleUpload} />
            </Button>
            <Button
              variant="contained"
              startIcon={<InfoIcon />}
              color="warning"
              onClick={() => navigate(`/photo-guide`)}
            >
              Görsel Ekleme Kılavuzu
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          id="photo-gallery"
          style={{ marginTop: "30px" }}
        >
          {photos.map((photo) => (
            <Grid item xs={2} key={photo.id}>
              <Item className="photo-item">
                <a
                  href={env + photo.photoUrl}
                  rel="noreferrer"
                  data-pswp-width={photo.width}
                  data-pswp-height={photo.height}
                >
                  <img src={env + (photo.photoThumb || photo.photoUrl)} alt="" />
                </a>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => handleDelete(photo.id)}
                  style={{ marginTop: "20px" }}
                >
                  Sil
                </Button>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </AdminLayout>
  );
}