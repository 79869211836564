import {
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { SketchPicker } from "react-color";
import { env } from "../../app/utils/env";

interface AdminFieldProps {
  label?: String;
  type?:
    | "text"
    | "date"
    | "switch"
    | "select"
    | "radio"
    | "number"
    | "decimal"
    | "color"
    | "button"
    | "email"
    | "password"
    | "disabled";
  value: any;
  onChange: (value: any) => void;
  selectOptions?: { label: string; value: any }[];
  helperText?: String;
  fullWidth?: boolean | undefined;
  inline?: boolean;
  inlineFix?: number;
  error?: boolean;
  errorMessage?: string;
  buttonText?: string;
  isLogin?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export default function AdminField({
  label,
  type = "text",
  value,
  onChange,
  selectOptions = [],
  helperText,
  fullWidth = true,
  inline = false,
  inlineFix,
  error = false,
  errorMessage = "",
  buttonText,
  isLogin = false,
  disabled = false,
  onClick,
}: AdminFieldProps) {
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    onChange(newValue);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const handleColorChange = (color: any) => {
    onChange(color.hex);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value !== '' ? parseFloat(event.target.value) : '');
  };

  return (
    <>
      {label && !isLogin && (
        <Grid item xs={inline ? 1 : 2}>
          <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>{label}</Typography>
        </Grid>
      )}
      <Grid item xs={type === "disabled" ? 1 : (isLogin ? 12 : (inline ? 2 : 10))} sx={type === "button" && !inline ? { display: 'flex', alignItems: 'flex-start', gap: '20px' }: {}}>
        {type === "text" && (
          <TextField
            label={label}
            fullWidth={fullWidth}
            variant="outlined"
            value={value || ''}
            onChange={handleTextChange}
            helperText={error ? errorMessage : helperText}
            error={error}
          />
        )}
        {type === "email" && (
          <TextField
            label={label}
            type="email"
            fullWidth={fullWidth}
            variant="outlined"
            value={value || ''}
            onChange={handleTextChange}
            helperText={error ? errorMessage : helperText}
            error={error}
          />
        )}
        {type === "password" && (
          <TextField
            label={label}
            type="password"
            fullWidth={fullWidth}
            variant="outlined"
            value={value || ''}
            onChange={handleTextChange}
            helperText={error ? errorMessage : helperText}
            error={error}
          />
        )}
        {type === "disabled" && (
          <TextField
            label={label}
            disabled
            value={value || ''}
          />
        )}
        {type === "date" && (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <DateTimePicker
              value={value || null}
              onChange={handleDateChange}
              slotProps={{textField: {error: error, helperText: error ? errorMessage : helperText}}}
            />
          </LocalizationProvider>
        )}
        {type === "select" && (
          <TextField
            select
            fullWidth
            label={label}
            disabled={disabled}
            value={value || ''}
            onChange={handleTextChange}
            helperText={error ? errorMessage : helperText}
            error={error}
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {type === "switch" && (
          <>
            <Switch checked={Boolean(value)} onChange={handleSwitchChange} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
        {type === "radio" && (
          <>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value || ''}
              onChange={(event) => onChange(event.target.value)}
            >
              {selectOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
        {type === "number" && (
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            value={value !== null && value !== undefined ? value : ''}
            onChange={handleNumberChange}
            helperText={error ? errorMessage : helperText}
            error={error}
          />
        )}
        {type === "decimal" && (
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            value={value !== null && value !== undefined ? value : ''}
            onChange={handleNumberChange}
            helperText={error ? errorMessage : helperText}
            error={error}
            inputProps={{ step: "0.01", min: 0, max: 1 }}
          />
        )}
        {type === "color" && (
          <>
            <SketchPicker color={value} onChangeComplete={handleColorChange} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
        {type === "button" && (
          <>
            <img
              className="placeholder-img"
              src={value ? env + value : `${process.env.PUBLIC_URL}/assets/placeholder-1.jpg`}
              height="60"
              width="60"
              alt="Selected"
            />
            <TextField
              label={label}
              variant="outlined"
              value={value || ''}
              onChange={handleTextChange}
              helperText={error ? errorMessage : helperText}
              error={error}
              sx={{ marginRight: '10px' }}
            />
            <Button variant="contained" color="primary" onClick={onClick} sx={{ mt: 1 }}>
              {buttonText || 'Seç'}
            </Button>
          </>
        )}
      </Grid>
      {inlineFix && <Grid item xs={inlineFix}></Grid>}
    </>
  );
}