import axios, { AxiosError, AxiosResponse } from 'axios';
import { env } from "../../app/utils/env";
import { Campaign } from '../models/campaign';
import { GalleryPhoto } from '../models/galleryPhoto';
import { User, UserFormValues } from '../models/user';
import { toast } from 'react-toastify';
import { store } from '../stores/store';
import { Game } from '../models/game';
import { Player } from '../models/player';
import { Slice } from '../models/slice';
import { DashboardData } from '../models/dashboard';
import { GameDto } from '../models/gameDto';
import { StatisticsDto } from '../models/statisticsDto';

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

axios.defaults.baseURL = env + "/api";

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  
  // Set Content-Type for non-GET requests
  if (config.method !== 'get' && config.headers && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

axios.interceptors.response.use(async response => {
  // await sleep(1000);
  return response;
}, (error: AxiosError) => {
  const {data, status} = error.response as AxiosResponse;
  switch (status) {
    case 400:
      if (data.errors) {
        const modalStateErrors = [];
        for (const key in data.errors) {
          if (data.errors[key]) {
            modalStateErrors.push(data.errors[key]);
          }
        }
        throw modalStateErrors.flat();
      } else {
        toast.error(data);
      }
      break;
    case 401:
      toast.error('Yetkisiz işlem');
      break;
    case 403:
      toast.error('Geçersiz işlem');
      break;
    case 404:
      toast.error('Bulunamadı!');
      break;
    case 500:
      store.commonStore.setServerError(data);
      toast.error(data.error);
      break;
  }
  return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T> (url: string) => axios.get<T>(url).then(responseBody),
  post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T> (url: string) => axios.post<T>(url).then(responseBody),
  upload: <T>(url: string, formData: FormData) => axios.post<T>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const Campaigns = {
  list: () => requests.get<Campaign[]>('/campaigns'),
  details: (id: string) => requests.get<Campaign>(`/campaigns/${id}`),
  create: (campaign: any) => requests.post<void>('/campaigns', campaign),
  update: (campaign: any) => requests.post<void>(`/campaigns/${campaign.id}`, campaign),
  delete: (id: string) => requests.del<void>(`/campaigns/deleteCampaign/${id}`),
  listOrder: (id: string, direction: string) => requests.post<void>('/campaigns/updateListOrder', {id: id, direction: direction}),
  listLight: () => requests.get<{ id: string, title: string }[]>('/campaigns'),
}

const Slices = {
  listOrder: (id: string, wheelId: string, direction: string) => requests.post<void>('/slices/updateListOrder', {id: id, wheelId: wheelId, direction: direction}),
  update: (slice: Slice) => requests.post<void>(`/slices/${slice.id}`, slice),
  listOfWheel: (wheelId: string) => requests.get<Slice[]>(`/slices/getWheelSlices/${wheelId}`),
}

const GalleryPhotos = {
  list: () => requests.get<GalleryPhoto[]>('/galleryPhotos'),
  create: (formData: FormData) => requests.upload<void>('/galleryPhotos', formData),
  delete: (id: string) => requests.del<void>(`/galleryPhotos/deleteGalleryPhoto/${id}`),
}

const Account = {
  current: () => requests.get<User>('account'),
  login: (user: UserFormValues) => requests.post<User>('account/login', user),
  register: (user: UserFormValues) => requests.post<User>('account/register', user)
}

const Games = {
  list: () => requests.get<GameDto[]>('/games'),
  create: (game: Game) => requests.post<void>('/games', game),
  getDashboard: () => requests.get<DashboardData>('/games/getDashboard'),
  statistics: (campaignId: string) => requests.get<StatisticsDto[]>(`/games/getStatistics/${campaignId}`),
};

const Players = {  
  create: (player: Player) => requests.post<void>('/players', player),
  update: (player: Player) => requests.post<void>(`/players/${player.id}`, player),
  deleteAll: (campaignId: any) => requests.del<void>(`players/deleteAll/${campaignId}`),
};

const agent = {
  Campaigns,
  Slices,
  GalleryPhotos,
  Account,
  Games,
  Players
}

export default agent;