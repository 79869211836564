interface DashboardItemProps {
  icon: any,
  text: string,
  count: number,
  color:
  | "green"
  | "red"
  | "blue"
  | "purple"
}

export default function DashboardItem({ icon, text, count, color }: DashboardItemProps) {
  return (
    <div className={`dashboard-item ${color}`}>
      <div className="content">
        <p>{text}</p>
        <h3>{count}</h3>
      </div>
      <div className="icon">
        {icon}
      </div>
    </div>
  );
}
