import SpinWheel from "../../components/SpinWheel/SpinWheel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Campaign } from "../models/campaign";
import agent from "../api/agent";
import CenteredCard from "../layout/CenteredCard";
import { env } from "../../app/utils/env";

export default function GameBoard() {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      if (id) {
        try {
          const fetchedCampaign = await agent.Campaigns.details(id);
          fetchedCampaign.wheel.slices.sort((a, b) => a.listOrder - b.listOrder);
          fetchedCampaign.wheel.slices.forEach(slice => {
            slice.probability = slice.probability ? parseFloat(slice.probability.toFixed(2)) : 0;
          });
          setCampaign(fetchedCampaign);
        } catch (error) {
          console.error("Failed to fetch campaign:", error);
        }
      } else {
        console.error("No campaign id provided");
      }
    };
    fetchCampaign();
  }, [id]);

  if (!campaign) {
    return <CenteredCard>Yükleniyor...</CenteredCard>;
  }

  return (
    <main
      className="game-screen"
      style={{
        backgroundColor: campaign.wheel.screenColor || "#E60805",
        color: campaign.wheel.textColor || "fff",
        backgroundImage: `url(${env + campaign.wheel.screenBg})` || "none",
      }}
    >
      {campaign.wheel.logo && (
        <img
          className="screen-logo"
          src={env + campaign.wheel.logo}
          alt={campaign.title}
        ></img>
      )}
      <h3>{campaign.wheel.pageTitle}</h3>
      <SpinWheel
        collectUserData={campaign.collectUserData}
        wheel={campaign.wheel}
        slices={campaign.wheel.slices}
        campaignId={campaign.id}
      />
    </main>
  );
}
