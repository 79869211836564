import { Alert, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

interface GuideCardProps {
  title: string;
  location: string;
  dimensions: string;
  format?: string;
  images?: { name: string; width: number, height: number }[];
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f6f6f6",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

export default function GuideCard({ title, location, dimensions, format = ".jpg, .jpeg, .png, .webp", images = [] }: GuideCardProps) {
  return (
    <>      
      <Grid item xs={12} md={6}>
            <Item className="guide-item">
              <Alert icon={false} variant="outlined" severity="info">
                <h3>{title}:</h3>
                <pre>
                  <strong>Yeri    :</strong> {location} <br />
                  <strong>Ölçüler :</strong> {dimensions} <br />
                  <strong>Format  :</strong> {format} <br />
                </pre>
                <Grid container spacing={2}>
                  {images.map((image) => (
                    <Grid key={image.name} item xs={6} md={4}>
                      <a
                        className="guide-photo"
                        href={`${process.env.PUBLIC_URL}/assets/guide/${image.name}`}
                        rel="noreferrer"
                        data-pswp-width={image.width}
                        data-pswp-height={image.height}
                      >
                        <img src={`${process.env.PUBLIC_URL}/assets/guide/${image.name}`} alt={title} width="200" height="200" />
                      </a>
                    </Grid>
                  ))}
                </Grid>
              </Alert>
            </Item>
          </Grid>
    </>
  );
}
