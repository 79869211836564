import { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Chip } from "@mui/material";
import agent from "../api/agent";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { StatisticsDto } from "../models/statisticsDto";

export default function Statistics() {
  const { id } = useParams<{ id: string }>();
  const [statistics, setStatistics] = useState<StatisticsDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      agent.Games.statistics(id)
        .then(response => {
          setStatistics(response);
          setLoading(false);
        })
        .catch(error => {
          console.error('Failed to fetch statistics:', error);
          toast.error('Failed to fetch statistics');
          setLoading(false);
        });
    }
  }, [id]);

  const columns: GridColDef[] = [
    {
      field: "prize",
      headerName: "Ödül",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Dağıtılan adet",
      width: 200,
    },
    {
      field: "remainingStock",
      headerName: "Kalan stok",
      width: 200,
    },
    {
      field: "datePlayed",
      headerName: "Son Kazanıldığı Tarih",
      width: 200,
      renderCell: (params: any) =>
        params.row.amount > 0 ? (
          dayjs(params.value as string).format("DD.MM.YYYY HH:mm")
        ) : (
          <Chip label="Henüz kazanılmadı" />
        ),
    },
  ];

  return (
    <>
      <AdminLayout title="İstatistikler">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/campaigns/${id}`)}
          sx={{ mb: 2, mr: 3 }}
        >
          Kampanyaya Dön
        </Button>
        <DataGrid
          rows={statistics}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          getRowId={(row) => row.prize}
          pageSizeOptions={[10, 25]}
          checkboxSelection
        />
      </AdminLayout>
    </>
  );
}
