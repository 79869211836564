import { useEffect, useState } from "react";
import "./SpinEffect.css";

interface SpinEffectProps {
  mustSpin: boolean;
  dataLength: number;
}

export default function SpinEffect({mustSpin, dataLength}: SpinEffectProps) {
  const [flashIndex, setFlashIndex] = useState<number>(0);
  const sliceAngle = 360 / dataLength;
  let difference = 0;
  if (dataLength === 6) {
    difference = 15;
  } else if (dataLength === 10) {
    difference = -9
  }

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (!mustSpin) {
      interval = setInterval(() => {
        setFlashIndex((prevIndex) => (prevIndex + 1) % dataLength);
      }, 120);
    } else {
      setFlashIndex(0);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [mustSpin, dataLength]);
  
  return (
    <div className="flash-overlay-container">      
      {Array.from({ length: dataLength }).map((_, index) => (
        <div
          key={index}
          className={`flash-overlay overlay${dataLength} ${index % 2 === 0 ? "even" : "odd"} ${flashIndex === index ? "active" : ""}`}
          style={{
            transform: `scale(92%) rotate(${index * sliceAngle - difference}deg)`        
          }}
        />
      ))}
    </div>
  )
}