import { Grid } from "@mui/material";
import AdminLayout from "../layout/AdminLayout";
import DashboardItem from "../layout/DashboardItem";
import AttractionsIcon from "@mui/icons-material/Attractions";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { DashboardData } from "../models/dashboard";
import { useEffect, useState } from "react";
import agent from "../api/agent";
import { toast } from "react-toastify";
import CenteredCard from "../layout/CenteredCard";

export default function Dashboard() {
  const [data, setData] = useState<DashboardData>(null!);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await agent.Games.getDashboard();
      const fetchedData = response;
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Veriler getirilemedi.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <CenteredCard>Yükleniyor...</CenteredCard>;
  }

  if (!data) {
    return <CenteredCard>Veriler getirilemedi.</CenteredCard>;
  }

  return (
    <>
      <AdminLayout title="Dashboard">
        <Grid
          container
          spacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{
            my: { xs: 2, sm: 3, md: 4 },
          }}
          rowSpacing={12}
          columnSpacing={{ xs: 4, sm: 6, md: 12 }}
        >
          <Grid item xs={6}>
            <DashboardItem
              text="Toplam Kampanya"
              count={data.campaignsCount}
              color="red"
              icon={<AttractionsIcon />}
            />
          </Grid>
          <Grid item xs={6}>
            <DashboardItem
              text="Toplam Oyun"
              count={data.gamesCount}
              color="blue"
              icon={<VideogameAssetIcon />}
            />
          </Grid>
          <Grid item xs={6}>
            <DashboardItem
              text="Toplam Oyuncu"
              count={data.playersCount}
              color="purple"
              icon={<PersonIcon />}
            />
          </Grid>
          <Grid item xs={6}>
            <DashboardItem
              text="Toplam Ödül"
              count={data.prizesCount}
              color="green"
              icon={<EmojiEventsIcon />}
            />
          </Grid>
        </Grid>
      </AdminLayout>
    </>
  );
}
