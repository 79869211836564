import { Slice } from "../models/slice";

export const getWinningSlice = (slices: Slice[]): string => {
  const totalProbability = slices.reduce((sum, slice) => sum + (slice.probability || 0), 0);
  const unsetProbabilityCount = slices.filter(slice => !slice.probability).length;

  if (totalProbability === 0) {
    const randomSlice = Math.floor(Math.random() * slices.length);
    return slices[randomSlice].id;
  }

  const remainingProbability = (1.0 - totalProbability) / unsetProbabilityCount;
  const normalizedSlices: Slice[] = slices.map(slice => ({
    ...slice,
    probability: slice.probability || remainingProbability,
  }));

  const sumOfProbabilities = normalizedSlices.reduce((sum, slice) => sum + (slice.probability || 0), 0);
  const random = Math.random() * sumOfProbabilities;
  let cumulativeProbability = 0;

  for (let i = 0; i < normalizedSlices.length; i++) {
    cumulativeProbability += normalizedSlices[i].probability || 0;
    if (random < cumulativeProbability) {
      return normalizedSlices[i].id;
    }
  }
  return "";
};
