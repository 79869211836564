import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom"
import App from "../layout/App";
import Dashboard from "../pages/Dashboard";
import ListCampaigns from "../pages/ListCampaigns";
import CampaignDetails from "../pages/CampaignDetails";
import PhotoGallery from "../pages/PhotoGallery";
import ListPlayers from "../pages/ListPlayers";
import GameBoard from "../pages/GameBoard";
import LoginForm from "../pages/LoginForm";
import ServerError from "../utils/errors/ServerError";
import { useStore } from "../stores/store";
import Statistics from "../pages/Statistics";
import PhotoGuide from "../pages/PhotoGuide";

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { userStore } = useStore();
  return userStore.isLoggedIn ? children : <Navigate to="/login" />;
}

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {path: '', element: <PrivateRoute><Dashboard /></PrivateRoute>},
      {path: 'campaigns', element: <PrivateRoute><ListCampaigns /></PrivateRoute>},
      {path: 'campaigns', element: <PrivateRoute><ListCampaigns /></PrivateRoute>},
      {path: 'campaigns/:id', element: <PrivateRoute><CampaignDetails key='manage' /></PrivateRoute>},
      {path: 'campaigns/new', element: <PrivateRoute><CampaignDetails key='create' /></PrivateRoute>},
      {path: 'photo-gallery', element: <PrivateRoute><PhotoGallery /></PrivateRoute>},
      {path: 'photo-guide', element: <PrivateRoute><PhotoGuide /></PrivateRoute>},
      {path: 'players', element: <PrivateRoute><ListPlayers /></PrivateRoute>},
      {path: 'statistics/:id', element: <PrivateRoute><Statistics /></PrivateRoute>},
      {path: 'game/:id', element: <GameBoard />},
      {path: 'login', element: <LoginForm />},
      {path: 'errors', element: <PrivateRoute><h1>Error</h1></PrivateRoute>},
      {path: 'server-error', element: <PrivateRoute><ServerError /></PrivateRoute>},
    ]
  },

];

export const router = createBrowserRouter(routes);