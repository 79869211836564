import { Box, Card, CardContent } from "@mui/material";
import AdminHeader from "./AdminHeader";
import Sidenav from "./Sidenav";

interface AdminLayoutProps {
  title: String;
  children?: React.ReactNode;
}

export default function AdminLayout({ title, children }: AdminLayoutProps) {
  return (
    <>      
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box sx={{ width: "100%" }}>
          <AdminHeader />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, background: "#f6f6f6", height: "100%" }}
          >
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>{title}</h1>
                  {/* Place for additional buttons if needed */}
                </Box>
                {children}
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
}
